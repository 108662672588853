import React, { useRef, useState } from "react";
import Input from "./Input";
import { db, uploadFile } from "../../FirebaseConfig.ts";
import { addDoc, collection, getDocs, query, where } from "firebase/firestore";
import Spinner from "../../components/Spinner";
import { useSnapshot } from "../../hooks/useSnapshot.js";
import useUpdateDoc from "../../hooks/useUpdateDoc.js";

function FormStepTwo({
  setActiveMoney,
  activeMoney,
  setStep,
  data,
  setData,
  files,
  setFiles,
  sended,
  setSended,
}) {
  const form = useRef();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const { handleUpdate } = useUpdateDoc();

  const { data: tasa, isFetching } = useSnapshot("tasa");

  const sendCredit = async (e) => {
    e.preventDefault();

    setLoading(true);
    //sube el archivo a firebase

    const name = `${data.name} ${data.lastName}`;

    try {
      const uploadPromises = Object.keys(files).map(async (key) => {
        const file = files[key];

        if (file) {
          // Solo subimos si el archivo está presente
          const url = await uploadFile(file, `${name}/${key}`);
          return { [key]: url }; // Retornamos un objeto con el nombre del archivo como clave
        }
        return null;
      });

      const uploadResults = await Promise.all(uploadPromises);

      // Convertir los resultados en un objeto de URLs
      const fileUrls = uploadResults.reduce((acc, curr) => {
        if (curr) {
          return { ...acc, ...curr };
        }
        return acc;
      }, {});

      const res = await addDoc(collection(db, "requests"), {
        ...data,
        ...fileUrls,
        status: "pendiente",
        codeudor: "",
        tasa: tasa[0].number,
        start_date: "",
        companyId: "",
        ammount: data.ammount.replace(/\./g, ""),
      });

      handleUpdate("requests", res.id, { uuid: res.id });

      setFiles({
        cert_laboral: "",
        comp_nomina: "",
        fot_cedula: "",
        dec_renta: "",
      });
      setError("");
      setSended(true);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
      setError(
        "Ha ocurrido un error al mandar la solicitud, por favor intente nuevamente"
      );
      setSended(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <button
        onClick={() => {
          setStep(0);
          setActiveMoney(activeMoney);
        }}
      >
        <svg
          width="20px"
          height="20px"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20 12H4M4 12L10 6M4 12L10 18"
            stroke="#FFFFFF"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
      <form className="w-full mx-auto" ref={form} onSubmit={sendCredit}>
        <div className="grid grid-cols-1 md:grid-cols-2 md:gap-5">
          <Input
            label="Nombres"
            onChange={(e) => {
              setData({ ...data, name: e.target.value });
            }}
            value={data.name}
          />
          <Input
            label="Apellidos"
            onChange={(e) => {
              setData({ ...data, lastName: e.target.value });
            }}
            value={data.lastName}
          />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 md:gap-5">
          <Input
            label="Razón social de la empresa"
            onChange={(e) => {
              setData({ ...data, company: e.target.value });
            }}
            value={data.company}
          />
          <Input
            label="NIT"
            onChange={(e) => {
              setData({ ...data, NIT: e.target.value });
            }}
            value={data.NIT}
          />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 md:gap-5">
          <Input
            label="Dirección"
            onChange={(e) => {
              setData({ ...data, address: e.target.value });
            }}
            value={data.address}
          />
          <Input
            label="Teléfono"
            onChange={(e) => {
              setData({ ...data, cellphone: e.target.value });
            }}
            value={data.cellphone}
          />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 md:gap-5">
          <Input
            label="Cédula"
            onChange={(e) => {
              setData({ ...data, identity: e.target.value });
            }}
            value={data.identity}
          />

          <Input
            label="Lugar de Expedición"
            onChange={(e) => {
              setData({ ...data, expedition: e.target.value });
            }}
            value={data.expedition}
          />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 md:gap-5">
          <Input
            label="Certificación Laboral"
            type="file"
            onChange={(e) => {
              setFiles({ ...files, cert_laboral: e.target.files[0] });
            }}
            accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          />
          <Input
            label="Comprobante de nómina"
            type="file"
            onChange={(e) => {
              setFiles({ ...files, comp_nomina: e.target.files[0] });
            }}
            accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 md:gap-5">
          <Input
            label="Fotocopia de la cédula"
            type="file"
            onChange={(e) => {
              setFiles({ ...files, fot_cedula: e.target.files[0] });
            }}
            accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          />
          <Input
            label="Declaración de renta"
            type="file"
            onChange={(e) => {
              setFiles({ ...files, dec_renta: e.target.files[0] });
            }}
            accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          />
        </div>

        <div className="flex items-center justify-center py-3">
          <input
            id="link-checkbox"
            type="checkbox"
            value=""
            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
            required
          />
          <label
            htmlFor="link-checkbox"
            className="ms-2 text-sm font-medium text-white"
          >
            Estoy de acuerdo con los{" "}
            <a href="#" className="text-robin hover:underline">
              términos y condiciones
            </a>
            .
          </label>
        </div>
        {error && <p className="text-primary">{error}</p>}
        {sended && (
          <p className="text-green-400 mb-3">Solicitud enviada correctamente</p>
        )}
        <button
          type="submit"
          className="text-white rounded-lg px-5 py-2.5 text-center bg-secondary w-full font-bold text-xl"
          disabled={loading}
        >
          {loading ? <Spinner /> : "Solicitud de crédito"}
        </button>
      </form>
    </>
  );
}

export default FormStepTwo;
