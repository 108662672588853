import React from "react";
import Sidebar from "./components/Sidebar";
import Table from "./components/Table.js";
import LayoutPlatform from "./layout";

function Platform() {
  return (
    <div>
      <p className="text-xl font-bold mb-3">Solicitudes de crédito</p>
      <Table />
    </div>
  );
}

export default Platform;
