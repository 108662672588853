import React from "react";

function Input({ label, value, type, black, placeholder, ...rest }) {
  return (
    <div className="mb-3">
      <label
        htmlFor="text"
        className={`${
          black ? "" : "text-white"
        } block mb-2 text-md font-medium `}
      >
        {label}
      </label>
      <input
        type={type ? type : "text"}
        id="text"
        className={`${
          black ? "" : "text-white"
        } bg-robin bg-opacity-20  text-sm rounded-lg block w-full p-2.5 focus-0`}
        placeholder={placeholder ? placeholder : ""}
        value={value}
        required
        {...rest}
      />
    </div>
  );
}

export default Input;
